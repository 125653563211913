import "react-modal-overlay/dist/index.css";
import Home from "./Pages/Home";

import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import Event from "./Pages/Event";
import Navbar from "./components/Navbar";

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Switch>
        <Route exact path="/event/:eventId" component={Event} />

        <Route exact path="/" component={Home} />

        {/* <Route path="*">
          <Whoops404 />
        </Route> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
{
  /* <Hero />
      <Features />
      <Testimonial />
      <CallToAction /> */
}
{
  /* <Footer /> */
}
