import { BrowserRouter } from "react-router-dom";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features.jsx";
import Testimonial from "../components/Testimonial";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { Modal } from "react-modal-overlay";
import "react-modal-overlay/dist/index.css";
import {
  Link,
  Button,
  Element,
  Events,
  ScrollElement,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Home() {
  const [data, setData] = useState([]);

  const location = useLocation();
  const [exhibition, setExhibition] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [date, setDate] = useState("");
  const [dates, setDates] = useState([]);
  const [noOfPhotographers, setNoOfPhotometers] = useState(0);
  const [allDayChecked, setAllDayChecked] = useState(true);
  const [selectedDaysArr, setSelectedDaysArr] = useState([]);

  //   const scrollElement = () => {
  //     var element = document.getElementById("exhibition");
  //     element.scrollIntoView();
  //   };
  useEffect(() => {
    //   setData(events);
    axios.defaults.baseURL = "https://booking-admin.pixelogik.ae/api";
    async function getData() {
      await axios
        .get("/events")
        .then(response => {
          setData(response.data.data);
          setDates(response.data.data);
        })
        .catch(error => console.log(error));
    }

    getData();
  }, [location.pathName]);

  const selectHandler = e => {
    setData(dates);
    if (e.target.value !== "All Exhibitions") {
      var newDates = [];

      dates.map(item => {
        if (
          new Date(item.start_date).toLocaleString("default", {
            month: "long",
          }) ==
            new Date(e.target.value).toLocaleString("default", {
              month: "long",
            }) ||
          new Date(item.end_date).toLocaleString("default", {
            month: "long",
          }) ==
            new Date(e.target.value).toLocaleString("default", {
              month: "long",
            })
        ) {
          newDates.push(item);
        }
      });

      setData(newDates);
    } else {
      setData(dates);
    }
  };

  return (
    <BrowserRouter>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            We Create, Capture and Connect
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
            Pixelogik Media partners with events, exhibitions, and conferences
            to capture the emotions of people, places, and opportunities as
            images and videos. These moments are then woven into beautiful
            storytelling with finesse and technical expertise for the world to
            see.
          </p>
        </div>
      </section>

      <div className="w-100  flex flex-row justify-center self-center align-middle">
        <select
          id="countries"
          onChange={selectHandler}
          className="w-[40%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="All Exhibitions">All Exhibitions</option>
          <option value="September 2023">September 2023</option>
          <option value="October 2023">October 2023</option>
          <option value="November 2023">November 2023</option>
          <option value="December 2023">December 2023</option>
        </select>
      </div>

      <div className="flex justify-center mt-5  mb-8">
        <ul className="w-[50%]  text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          {data.map(item => {
            return (
              <li
                key={item.id}
                className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600"
              >
                <button
                  className=" w-full hover:bg-slate-500 h-full hover:text-white p-2 rounded-xl"
                  onClick={() => {
                    // setExhibition(item.Event);
                    // setDate(item.Date);
                    // getDayesHandler(item.Date);

                    // scroll.scrollTo(500);
                    window.location.href = `/event/${item.id}`;
                  }}
                >
                  <span className=" float-left">{item.name}</span>
                  <span className=" float-right">
                    {item.start_date} - {item.end_date}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </BrowserRouter>
  );
}

export default Home;
{
  /* <Hero />
      <Features />
      <Testimonial />
      <CallToAction /> */
}
{
  /* <Footer /> */
}
