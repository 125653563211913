import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Modal } from "react-modal-overlay";
import "react-modal-overlay/dist/index.css";
import {
  Link,
  Button,
  Element,
  Events,
  ScrollElement,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cart from "./Cart";
export default function Event() {
  const { eventId } = useParams();

  const [data, setData] = useState([]);

  const [event, setEvent] = useState({});
  const location = useLocation();
  const [exhibition, setExhibition] = useState("");
  const [cart, setCart] = useState([]);

  const [dates, setDates] = useState([]);
  const [noOfPhotographers, setNoOfPhotgraphers] = useState(0);
  const [allDayChecked, setAllDayChecked] = useState(false);
  const [fullDayChecked, setFullDayChecked] = useState(false);
  const [halfDayChecked, setHalfDayChecked] = useState(false);
  const [selectedDaysArr, setSelectedDaysArr] = useState([]);
  const [exhibitionPhotograpyData, setExhibitionPhotograpyData] = useState([]);

  useEffect(() => {
    axios.defaults.baseURL = "https://booking-admin.pixelogik.ae/api";

    async function getData() {
      await axios
        .get(`/event/${eventId}`)
        .then(response => {
          setExhibition(response.data.data.name);
          setEvent(response.data.data);
          getDayesHandler(
            response.data.data.start_date,
            response.data.data.end_date,
          );
        })
        .catch(error => console.log(error.response));
      // console.log(dates);
    }

    getData();
  }, [location.pathName]);

  function getDayesHandler(d1, d2) {
    for (
      var arr = [], dt = new Date(d1);
      dt <= new Date(d2);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(
        new Date(dt).toLocaleString("en", {
          weekday: "short",
          year: "2-digit",
          month: "short",
          day: "numeric",
        }),
      );
    }

    var newExPhotographArr = [];
    arr.forEach(date => {
      console.log(date);
      newExPhotographArr.push({
        date: date,
        fullDay: false,
        halfDay: false,
      });
    });

    setExhibitionPhotograpyData(newExPhotographArr);
    console.log(newExPhotographArr);

    // setDates(arr);
  }

  const handleSelectedDaysArray = e => {
    if (selectedDaysArr.includes(e.target.value)) {
      var newPeople = selectedDaysArr.filter(
        person => person !== e.target.value,
      );

      setSelectedDaysArr(newPeople);
    } else {
      setSelectedDaysArr(current => [...current, e.target.value]);
    }
  };
  return (
    <Element name="exb-div">
      <section class="bg-white dark:bg-gray-900" id="exhibition">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            {exhibition}
          </h1>
        </div>
        <div className="  text-center flex justify-center ">
          <div className="lg:w-[60%] md:w-[100%] mb-8 p-6  self-center">
            <div className=" flex justify-center w-full self-center">
              <fieldset className=" w-full self-center">
                <h6 class="flex justify-center items-center mb-4 text-lg text-left font-bold  tracking-tight leading-none text-gray-900 md:text-lg lg:text-lg dark:text-white">
                  Event/Exhibtion Photography
                  <div className="ml-3">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 1024 1024"
                      class="icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z"
                        fill="#2196F3"
                      />
                      <path
                        d="M469.333333 469.333333h85.333334v234.666667h-85.333334z"
                        fill="#FFFFFF"
                      />
                      <path
                        d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </div>
                </h6>
                <div className="flex justify-start">
                  <label
                    for="no_photographers"
                    class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Number of photographers required
                  </label>

                  <select
                    id="no_photographers"
                    onChange={e => {
                      setNoOfPhotgraphers(e.target.value);
                      e.target.value == 0
                        ? setAllDayChecked(false)
                        : setAllDayChecked(true);
                      e.target.value == 0
                        ? setFullDayChecked(false)
                        : setFullDayChecked(true);
                    }}
                    value={noOfPhotographers}
                    class="w-fit bg-gray-50 border ml-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                <div class="relative overflow-x-auto mt-8">
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" class="px-6 py-3"></th>
                        <th scope="col" class="px-6 py-3">
                          Full Day
                          <br />
                          <span>(upto 8 hrs) AED2,500</span>
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Half Day
                          <br />
                          <span>(upto 4 hrs) AED2,000</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-6 py-4">
                          <div class="flex items-center mb-4">
                            <label
                              for="default-checkbox"
                              class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              All Days
                            </label>
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              checked={allDayChecked}
                              onChange={e => {
                                setAllDayChecked(!allDayChecked);
                              }}
                              value={allDayChecked}
                              disabled={
                                (noOfPhotographers == 0 && true) ||
                                (noOfPhotographers == 0 && true)
                              }
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                        <td class="px-6 py-4">
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              type="radio"
                              checked={fullDayChecked}
                              onChange={e => {
                                console.log(e.target.value);
                                setFullDayChecked(!fullDayChecked);
                                setHalfDayChecked(!halfDayChecked);

                                selectedDaysArr.length !== 0 &&
                                  setAllDayChecked(!allDayChecked);

                                // console.log(e.target.value);
                              }}
                              value={"fullDay"}
                              name="fullOrHalfDay"
                              disabled={noOfPhotographers == 0 && true}
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                        <td class="px-6 py-4">
                          <div class="flex items-center mb-4">
                            <input
                              id="default-checkbox"
                              // checked={halfDayChecked}
                              type="radio"
                              name="fullOrHalfDay"
                              onChange={e => {
                                setHalfDayChecked(!halfDayChecked);
                                setFullDayChecked(!fullDayChecked);
                              }}
                              value={halfDayChecked}
                              // value={"halfDay"}
                              disabled={noOfPhotographers == 0 && true}
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                      </tr>
                      {exhibitionPhotograpyData.map(exchib => {
                        return (
                          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <label
                                  for="default-checkbox"
                                  class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {exchib.date}
                                </label>
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked == true && true}
                                  disabled={
                                    allDayChecked == true
                                      ? true
                                      : false ||
                                        (noOfPhotographers == 0 && true)
                                  }
                                  onChange={handleSelectedDaysArray}
                                  value={exchib.date}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="radio"
                                  checked={
                                    fullDayChecked == true
                                      ? true
                                      : exchib.fullDay
                                  }
                                  name={`${exchib.date} day`}
                                  // onChange={e => {
                                  //   setHalfDayChecked(false);
                                  //   exhibitionPhotograpyData.map(d => {
                                  //     if (d.date !== exchib.date) {
                                  //       d.halfDay = !d.halfDay;
                                  //     }
                                  //     if (d.date == exchib.date) {
                                  //       d.fullDay = !d.fullDay;
                                  //     }
                                  //   });
                                  //   // setFullDayChecked(false);
                                  // }}
                                  disabled={
                                    fullDayChecked == true
                                      ? true
                                      : false ||
                                        (noOfPhotographers == 0 && true)
                                  }
                                  value={exchib.fullDay}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  name={`${exchib.date} day`}
                                  type="radio"
                                  checked={
                                    halfDayChecked == true
                                      ? true
                                      : exchib.halfDay
                                  }
                                  // onChange={e => {
                                  //   setFullDayChecked(false);
                                  //   exhibitionPhotograpyData.map(d => {
                                  //     if (d.date !== exchib.date) {
                                  //       d.fullDay = !d.fullDay;
                                  //     }
                                  //   });
                                  //   // setFullDayChecked(false);
                                  // }}
                                  disabled={
                                    halfDayChecked == true
                                      ? true
                                      : false ||
                                        (noOfPhotographers == 0 && true)
                                  }
                                  value={exchib.halfDay}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div>
                    <h6 class="flex mt-6 justify-center items-center mb-4 text-lg text-left font-bold  tracking-tight leading-none text-gray-900 md:text-lg lg:text-lg dark:text-white">
                      Editing of Photos
                      <div className="ml-3">
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 1024 1024"
                          class="icon"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z"
                            fill="#2196F3"
                          />
                          <path
                            d="M469.333333 469.333333h85.333334v234.666667h-85.333334z"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </div>
                    </h6>

                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" class="px-6 py-3"></th>
                          <th scope="col" class="px-6 py-3">
                            Regular
                            <br />
                            <span>(within 24 hrs)</span>
                          </th>
                          <th scope="col" class="px-6 py-3">
                            Faster <br />
                            <span> (within 6hrs )</span>
                          </th>
                          <th scope="col" class="px-6 py-3">
                            Fastest <br />
                            <span> (on spot with deicated photo editor)</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td class="px-6 py-4">
                            <div class="flex items-center mb-4">
                              <label
                                for="default-checkbox"
                                class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                All Days
                              </label>
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                checked={allDayChecked}
                                onChange={e => {
                                  setAllDayChecked(!allDayChecked);
                                  // console.log(e.target.value);
                                }}
                                value={allDayChecked}
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </td>

                          <td class="px-6 py-4">
                            <div class="flex items-center mb-4">
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                checked={allDayChecked}
                                onChange={e => {
                                  selectedDaysArr.length !== 0 &&
                                    setAllDayChecked(!allDayChecked);
                                  // console.log(e.target.value);
                                }}
                                value={allDayChecked}
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </td>
                          <td class="px-6 py-4">
                            <div class="flex items-center mb-4">
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                value=""
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </td>
                          <td class="px-6 py-4">
                            <div class="flex items-center mb-4">
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                value=""
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>
                          </td>
                        </tr>
                        {dates.map(selectedDate => {
                          return (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <label
                                    for="default-checkbox"
                                    class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    {selectedDate}
                                  </label>
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={
                                      selectedDaysArr.length == 0
                                        ? allDayChecked == true
                                          ? true
                                          : false
                                        : selectedDaysArr.includes(selectedDate)
                                    }
                                    disabled={
                                      allDayChecked == true ? true : false
                                    }
                                    onChange={handleSelectedDaysArray}
                                    value={selectedDate}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={
                                      allDayChecked == true ? true : false
                                    }
                                    disabled={
                                      allDayChecked == true ? true : false
                                    }
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="w-[100%]  text-center flex justify-center ">
              <center className="w-[100%] mb-8 p-6  self-center">
                <div className=" flex justify-center w-full self-center">
                  <fieldset className=" w-full self-center">
                    <h6 class="flex justify-center items-center mb-4 text-lg text-left font-bold  tracking-tight leading-none text-gray-900 md:text-lg lg:text-lg dark:text-white">
                      Highlights Videography
                      <div className="ml-3">
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 1024 1024"
                          class="icon"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z"
                            fill="#2196F3"
                          />
                          <path
                            d="M469.333333 469.333333h85.333334v234.666667h-85.333334z"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </div>
                    </h6>
                    <div className="flex justify-start">
                      <label
                        for="no_photographers"
                        class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Number of videographers required
                      </label>

                      <select
                        id="no_photographers"
                        onChange={e => {
                          setNoOfPhotgraphers(e.target.value);
                        }}
                        class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option>Choose Number of videographers</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                    {noOfPhotographers !== 0 && (
                      <div class="relative overflow-x-auto mt-8">
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" class="px-6 py-3"></th>
                              <th scope="col" class="px-6 py-3">
                                Full Day
                                <br />
                                <span>(upto 8 hrs) AED2,500</span>
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Half Day
                                <br />
                                <span>(upto 4 hrs) AED2,000</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <label
                                    for="default-checkbox"
                                    class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    All Days
                                  </label>
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={allDayChecked}
                                    onChange={e => {
                                      setAllDayChecked(!allDayChecked);
                                      // console.log(e.target.value);
                                    }}
                                    value={allDayChecked}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={allDayChecked}
                                    onChange={e => {
                                      selectedDaysArr.length !== 0 &&
                                        setAllDayChecked(!allDayChecked);
                                      // console.log(e.target.value);
                                    }}
                                    value={allDayChecked}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                            </tr>
                            {dates.map(selectedDate => {
                              return (
                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <label
                                        for="default-checkbox"
                                        class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {selectedDate}
                                      </label>
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={
                                          selectedDaysArr.length == 0
                                            ? allDayChecked == true
                                              ? true
                                              : false
                                            : selectedDaysArr.includes(
                                                selectedDate,
                                              )
                                        }
                                        disabled={
                                          allDayChecked == true ? true : false
                                        }
                                        onChange={handleSelectedDaysArray}
                                        value={selectedDate}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={
                                          allDayChecked == true ? true : false
                                        }
                                        disabled={
                                          allDayChecked == true ? true : false
                                        }
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <h6 class="flex mt-6 justify-center items-center mb-4 text-lg text-left font-bold  tracking-tight leading-none text-gray-900 md:text-lg lg:text-lg dark:text-white">
                          Editing of Video
                        </h6>
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" class="px-6 py-3">
                                Editing of Video
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Regular
                                <br />
                                <span>
                                  (within 24 hrs)
                                  <br />
                                </span>
                                aed 800
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Faster <br />
                                <span> (within 6hrs )</span>
                                <br />
                                aed 1500
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Fastest <br />
                                <span>
                                  {" "}
                                  (on spot with deicated photo editor)
                                </span>
                                <br />
                                aed 2500
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <label
                                    for="default-checkbox"
                                    class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    All Days
                                  </label>
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={allDayChecked}
                                    onChange={e => {
                                      setAllDayChecked(!allDayChecked);
                                      // console.log(e.target.value);
                                    }}
                                    value={allDayChecked}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>

                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={allDayChecked}
                                    onChange={e => {
                                      selectedDaysArr.length !== 0 &&
                                        setAllDayChecked(!allDayChecked);
                                      // console.log(e.target.value);
                                    }}
                                    value={allDayChecked}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                            </tr>
                            {dates.map(selectedDate => {
                              return (
                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <label
                                        for="default-checkbox"
                                        class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {selectedDate}
                                      </label>
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={
                                          selectedDaysArr.length == 0
                                            ? allDayChecked == true
                                              ? true
                                              : false
                                            : selectedDaysArr.includes(
                                                selectedDate,
                                              )
                                        }
                                        disabled={
                                          allDayChecked == true ? true : false
                                        }
                                        onChange={handleSelectedDaysArray}
                                        value={selectedDate}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={
                                          allDayChecked == true ? true : false
                                        }
                                        disabled={
                                          allDayChecked == true ? true : false
                                        }
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <h6 class="flex mt-6 justify-center items-center mb-4 text-lg text-left font-bold  tracking-tight leading-none text-gray-900 md:text-lg lg:text-lg dark:text-white">
                          Live Photo Printing Service{" "}
                          <div className="ml-3">
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 1024 1024"
                              class="icon"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z"
                                fill="#2196F3"
                              />
                              <path
                                d="M469.333333 469.333333h85.333334v234.666667h-85.333334z"
                                fill="#FFFFFF"
                              />
                              <path
                                d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z"
                                fill="#FFFFFF"
                              />
                            </svg>
                          </div>
                        </h6>
                        <table class="w-full  text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" class="px-6 py-3"></th>
                              <th scope="col" class="px-6 py-3">
                                300 images printed
                                <br />
                                <span>AED 3500</span>
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Paper Frame <br />
                                <span> AED 1500</span>
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Paper Folder <br />
                                <span> AED 1700</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <label
                                    for="default-checkbox"
                                    class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    All Days
                                  </label>
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={allDayChecked}
                                    onChange={e => {
                                      setAllDayChecked(!allDayChecked);
                                      // console.log(e.target.value);
                                    }}
                                    value={allDayChecked}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>

                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={allDayChecked}
                                    onChange={e => {
                                      selectedDaysArr.length !== 0 &&
                                        setAllDayChecked(!allDayChecked);
                                      // console.log(e.target.value);
                                    }}
                                    value={allDayChecked}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </div>
                              </td>
                            </tr>
                            {dates.map(selectedDate => {
                              return (
                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <label
                                        for="default-checkbox"
                                        class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {selectedDate}
                                      </label>
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={
                                          selectedDaysArr.length == 0
                                            ? allDayChecked == true
                                              ? true
                                              : false
                                            : selectedDaysArr.includes(
                                                selectedDate,
                                              )
                                        }
                                        disabled={
                                          allDayChecked == true ? true : false
                                        }
                                        onChange={handleSelectedDaysArray}
                                        value={selectedDate}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={
                                          allDayChecked == true ? true : false
                                        }
                                        disabled={
                                          allDayChecked == true ? true : false
                                        }
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                  <td class="px-6 py-4">
                                    <div class="flex items-center mb-4">
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value=""
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="flex mt-8 justify-start">
                          <label
                            for="no_photographers"
                            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Number of cameras required
                          </label>

                          <select
                            id="no_photographers"
                            onChange={e => {
                              setNoOfPhotgraphers(e.target.value);
                            }}
                            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option>Number of cameras required</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Instant Photo Fun
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    selectedDaysArr.length !== 0 &&
                                      setAllDayChecked(!allDayChecked);
                                    // console.log(e.target.value);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <p class="px-2">Camera with upto 50 films.</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Dates Required
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center  flex-row">
                                <label
                                  for="default-checkbox"
                                  class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  All Days
                                </label>
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    setAllDayChecked(!allDayChecked);
                                    // console.log(e.target.value);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                {dates.map(d => {
                                  return (
                                    <div className="flex dark:bg-darkBlue  bg-gray-200 m-2 p-1 align-middle rounded-lg">
                                      <label
                                        for="default-checkbox"
                                        class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {d}
                                      </label>
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={allDayChecked}
                                        onChange={e => {
                                          setAllDayChecked(!allDayChecked);
                                          // console.log(e.target.value);
                                        }}
                                        value={allDayChecked}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        </table>

                        <div className="flex mt-8 justify-start">
                          <label
                            for="no_photographers"
                            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                          >
                            360o Videobooth
                          </label>
                        </div>
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              360o Videobooth
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    selectedDaysArr.length !== 0 &&
                                      setAllDayChecked(!allDayChecked);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <p class="px-2">360o Video booth.</p>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Dates Required
                            </th>

                            <td class="px-6 py-4">
                              <div class="flex items-center  flex-row">
                                <label
                                  for="default-checkbox"
                                  class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  All Days
                                </label>
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    setAllDayChecked(!allDayChecked);
                                    // console.log(e.target.value);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                {dates.map(d => {
                                  return (
                                    <div className="flex dark:bg-darkBlue  bg-gray-200 m-2 p-1 align-middle rounded-lg">
                                      <label
                                        for="default-checkbox"
                                        class="ml-2 mr-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {d}
                                      </label>
                                      <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        checked={allDayChecked}
                                        onChange={e => {
                                          setAllDayChecked(!allDayChecked);
                                          // console.log(e.target.value);
                                        }}
                                        value={allDayChecked}
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        </table>
                        <div className="flex mt-8 justify-start">
                          <label
                            for="no_photographers"
                            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Full Length Video Recording/ Live Streaming
                          </label>
                        </div>
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Package 1{" "}
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    selectedDaysArr.length !== 0 &&
                                      setAllDayChecked(!allDayChecked);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Package 2{" "}
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    selectedDaysArr.length !== 0 &&
                                      setAllDayChecked(!allDayChecked);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Package 3{" "}
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    selectedDaysArr.length !== 0 &&
                                      setAllDayChecked(!allDayChecked);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              Live Streaming{" "}
                            </th>
                            <td class="px-6 py-4">
                              <div class="flex items-center mb-4">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  checked={allDayChecked}
                                  onChange={e => {
                                    selectedDaysArr.length !== 0 &&
                                      setAllDayChecked(!allDayChecked);
                                  }}
                                  value={allDayChecked}
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    )}
                  </fieldset>
                </div>
              </center>
            </div>
          </div>

          <div className=" mb-8 p-6  self-center">{/* <Cart /> */}</div>
        </div>
      </section>
    </Element>
  );
}
{
  /*  */
}
